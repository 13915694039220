import * as React from "react"
import Layout from '../component/layout'
import Image from '../component/image'
import Testimonial from '../component/testimonial'
import JourneyOmnidya from '../component/journeyOmnidya'
import Link from '../component/link'


const Claims = () => {
  return (
    <Layout page="claims-header-bg" title="Claims" captions="Join the fastest growing auto insurance company in the West (and soon, the U.S.).">
      <div className="container-xxl">
        <div className="text-center py-5">
          <div className="header-slogan pb-3">
            How do I file a claim? 
          </div>
          <p>Worry not. It's easy.</p>
        </div>
        <div className="row justify-content-center pb-5">
          <div className="col col-md-auto text-sm-center">
            <div className="mb-5">
              <div className="mb-3">
                <Image src="uploadPics.svg" alt="Upload" />
              </div>
              <div className="sub-Title">
                1.Upload pics of  <br />the damage.
              </div>
            </div>
          </div>
          <div className="col col-md-auto">
            <div className="mb-5 text-sm-center text-right">
              <div className="mb-3">
                <Image src="describedIncedent.svg" alt="Described Incedent" />
              </div>
              <div className="sub-Title">
                2. Tell us exactly <br />what happened. 
              </div>
            </div>
          </div>
          <div className="col col-md-auto">
            <div className="mb-5 text-sm-center">
              <div className="mb-3">
                <Image src="trustUs.svg" alt="Keep Trust on Us" />
              </div>
              <div className="sub-Title">
                3. Then leave  <br />it to us. 
              </div>
            </div>
          </div>
        </div>
        <Testimonial />
        
        {/* FAQ Start */}
        <div className="text-center py-5">
          <div className="header-slogan pb-3">
            FAQ
          </div>
          <p>Got a question? We got answer</p>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <Link className="btn btn-blue btn-pill">
            Home Insurance
          </Link>
          <Link className="btn btn-pill">
            Auto Insurance
          </Link>
        </div>
        <div className="row justify-content-center">
          <div className="col col-lg-8">
            <div className="row pb-5">
              <div className="col-xs col-sm-5">
                <div className="nav flex-column nav-pills custom-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <Link className="nav-link active" id="yourride-tab" data-toggle="pill" href="#yourride" role="tab" aria-controls="yourride" aria-selected="true">
                      <div className="icon mr-sm-2">
                        <Image src="vehicle_shape.svg" className="svgIcon" />
                      </div>
                    Your Ride
                  </Link>
                  <Link className="nav-link" id="app-tab" data-toggle="pill" href="#app" role="tab" aria-controls="app" aria-selected="false">
                    <div className="icon mr-sm-2">
                        <Image src="download-2.svg" className="svgIcon" />
                    </div>
                    Omnidya App
                  </Link>
                  <Link className="nav-link" id="yourhome-tab" data-toggle="pill" href="#yourhome" role="tab" aria-controls="yourhome" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="bookmark_shape.svg" className="svgIcon" />
                    </div>
                    Your Home
                  </Link>
                  <Link className="nav-link" id="insurance-tab" data-toggle="pill" href="#insurance" role="tab" aria-controls="insurance" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="insurance_shape.svg" className="svgIcon" />
                    </div>
                    Insurance
                  </Link>
                  <Link className="nav-link" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="account" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="user_shape.svg" className="svgIcon" />
                    </div>
                    Account
                  </Link>
                </div>
              </div>
              <div className="col-xs col-sm-7">
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="yourride" role="tabpanel" aria-labelledby="yourride-tab">
                      <div id="accordionExample">
                        <div>
                          <div id="headingOne">
                            <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left" type="button" data-toggle="collapse" data-target="#downLoadApp" aria-expanded="true" aria-controls="downLoadApp">
                              <Image src="accordion-icon.svg" className="mr-3" />
                              How to Download the App
                            </Link>
                          </div>
                          <div id="downLoadApp" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="py-3">
                              What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ the world's best person for any job, regardless of location.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingTwo">
                            <button className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#howToRegister" aria-expanded="false" aria-controls="howToRegister">
                              <Image src="accordion-icon.svg" className="mr-3" />
                              How to register?
                            </button>
                          </div>
                          <div id="howToRegister" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="py-3">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingThree">
                            <button className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#removeCreditCard" aria-expanded="false" aria-controls="removeCreditCard">
                              <Image src="accordion-icon.svg" className="mr-3" />
                              How can I remove my credit card?
                            </button>
                          </div>
                          <div id="removeCreditCard" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="py-3">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingFour">
                            <button className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#addCreditCard" aria-expanded="false" aria-controls="addCreditCard">
                              <Image src="accordion-icon.svg" className="mr-3" />
                              How to add a Credit Card?
                            </button>
                          </div>
                          <div id="addCreditCard" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                            <div className="py-3">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            </div>
                          </div>
                        </div>
                      </div>
                    {/* <!-- <Image src="getQuote.svg" className="img-fluid" >
                    <div className="sub-Title my-3">Customized for you</div>
                    Tell us a bit about you and we’ll give you a personalized quote.  --></img> */}
                  </div>
                  <div className="tab-pane fade" id="app" role="tabpanel" aria-labelledby="app-tab">
                    <Image src="downloadApp.svg" fixed />
                    <div className="sub-Title my-3">Hit us up on the App Store or Google Play</div>
                    <p>Download our app for an easy way to manage your policy.</p>
                    <div>
                      <Image src="google-play-badge.png" fixed className="mr-sm-2" />
                      <Image src="app-store-badge-128-x-128.png" fixed />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="yourhome" role="tabpanel" aria-labelledby="yourhome-tab">
                    <Image src="freeDashcam.svg" fixed />
                    <div className="sub-Title my-3">Did you know? </div>
                    <p>There’s a 0.45% chance that when you get into an accident, you’ll be a victim of fraud. Our dashcam helps take that down to 0%.</p>
                  </div>
                  <div className="tab-pane fade" id="insurance" role="tabpanel" aria-labelledby="insurance-tab">
                    <Image src="drivingInsights.svg" fixed />
                    <div className="sub-Title my-3">Get them receipts and rewards </div>
                    <p>Use your new dashcam to track your driving. Uh oh, crash? You got receipts. Good driving? You’ll pay less.</p>
                  </div>
                  <div className="tab-pane fade" id="account" role="tabpanel" aria-labelledby="account-tab">
                    <Image src="drivingInsights.svg" fixed />
                    <div className="sub-Title my-3">Get them receipts and rewards </div>
                    <p>Use your new dashcam to track your driving. Uh oh, crash? You got receipts. Good driving? You’ll pay less.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FAQ End --> */}
      </div>
      <JourneyOmnidya gutter />
    </Layout>
  )
}

export default Claims
