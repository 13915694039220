import React from 'react'

import Image from './image'

const Testimonial = (props) => {
  return(
    <div id="carouselExampleCaptions" className="carousel slide pb-4" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="pinkBlue-bg text-center p-5">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 text-white">
                <div className="sub-Title font-weight-bold text-white">
                  Testimonial
                </div>
                <p>Sending love to our fans. We'd be nothing without them. </p>
                <div className="">
                  “Omnidya is a great tool because it allows you to create high and low fidelity prototypes without a problem. What I love most is that it is completely flexible with a range of useful tools and resources."
                </div>
              </div>
            </div>
          </div>
          <div className="dn-arrow pb-3 d-none d-sm-block"></div>
          <div className="row align-items-center justify-content-center text-center text-sm-left mt-n4 mt-sm-0">
            <div className="col-12 col-sm-auto">
              <Image src="christopher.png" alt="Christopher" fixed />
            </div>
            <div className="col-12 col-sm-auto">
              <div className="userName">Kumar Patel2</div>
              <p>
                Founder/CEO
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="pinkBlue-bg text-center p-5 rounded">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 text-white">
                <div className="sub-Title font-weight-bold text-white">
                  Testimonial
                </div>
                <p>Sending love to our fans. We'd be nothing without them. </p>
                <div className="">
                  “Omnidya is a great tool because it allows you to create high and low fidelity prototypes without a problem. What I love most is that it is completely flexible with a range of useful tools and resources."
                </div>
              </div>
            </div>
          </div>
          <div className="dn-arrow pb-3 d-none d-sm-block"></div>
          <div className="row align-items-center justify-content-center text-center text-sm-left mt-n4 mt-sm-0">
            <div className="col-12 col-sm-auto">
              <Image src="christopher.png" alt="Christopher" fixed />
            </div>
            <div className="col-12 col-sm-auto">
              <div className="userName">Kumar Patel</div>
              <p>
                Founder/CEO
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="pinkBlue-bg text-center p-5">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 text-white">
                <div className="sub-Title font-weight-bold text-white">
                  Testimonial
                </div>
                <p>Sending love to our fans. We'd be nothing without them. </p>
                <div className="">
                  “Omnidya is a great tool because it allows you to create high and low fidelity prototypes without a problem. What I love most is that it is completely flexible with a range of useful tools and resources."
                </div>
              </div>
            </div>
          </div>
          <div className="dn-arrow pb-3 d-none d-sm-block"></div>
          <div className="row align-items-center justify-content-center text-center text-sm-left mt-n4 mt-sm-0">
            <div className="col-12 col-sm-auto">
              <Image src="christopher.png" alt="Christopher" fixed />
            </div>
            <div className="col-12 col-sm-auto">
              <div className="userName">Kumar Patel</div>
              <p>
                Founder/CEO
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial